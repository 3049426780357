<template>
  <div class="app-body">
    <div class="a-flex-rsbc a-ptb-15">
      <span class="a-fs-16 a-fw-b">充值管理</span>
      <div class="a-flex-rcc">
        <le-export-btn :page-param="pageParam"></le-export-btn>
      </div>
    </div>
    <el-card class="el-main">
      <le-search-form :can-pack-up="false" @reset="handlerRest" @search="handlerSearch">
        <le-input
          v-model="pageParam.params.orderId"
          type="number"
          label="充值订单"
          placeholder="请输入充值订单"
        />
        <le-input
          v-model="pageParam.params.mobile"
          type="number"
          label="手机号码"
          placeholder="请输入手机号码"
        />
        <le-select-local-search
          v-model="pageParam.params.chargeType"
          label="充值类型"
          :options="rechargeTypeOptions"
        />
        <le-date-range
          label="支付日期"
          :min-date.sync="pageParam.params.payTimeStart"
          :max-date.sync="pageParam.params.payTimeEnd"
        />
      </le-search-form>
      <div class="a-mt-20 a-fs-14">累计充值金额: {{ totalAmount }}</div>
      <le-pagview :page-param="pageParam" :total.sync="total" @setData="setTableData">
        <el-table
          v-sticky="{ parent: '.el-card__body' }"
          :data="tableData"
          :highlight-current-row="true"
        >
          <el-table-column prop="__number__" label="序号" width="200"></el-table-column>
          <el-table-column prop="orderId" label="充值订单" width="250"></el-table-column>
          <el-table-column prop="chargeTypeLabel" label="充值类型" width="200"></el-table-column>
          <el-table-column prop="mobile" label="手机号码" width="200"></el-table-column>
          <el-table-column prop="amountYuan" label="充值金额(元)" width="200"></el-table-column>
          <el-table-column prop="orderTime" label="下单时间"></el-table-column>
          <el-table-column prop="payTime" label="支付时间"></el-table-column>
        </el-table>
        <template slot="footerLabel">
          <div class="a-flex-rcfe">
            <span class="a-c-666 a-fs-12">共</span>
            <span class="a-fs-16 a-fw-b a-plr-10">{{ total }}</span>
            <span class="a-c-666 a-fs-12">条数据</span>
          </div>
        </template>
      </le-pagview>
    </el-card>
  </div>
</template>

<script>
const initParams = () => {
  return {
    orderId: "",
    mobile: "",
    searchType: 1,
    chargeType: undefined,
    payType: undefined,
    payTimeStart: "",
    payTimeEnd: "",
  };
};

export default {
  name: "RechargeList",

  data() {
    return {
      total: 0,
      totalAmount: 0,
      tableData: [],
      pageParam: {
        url: this.$Config.apiUrl.getRechargeList,
        method: "post",
        params: initParams(),
        freshCtrl: 1,
      },
      rechargeTypeOptions: [
        {
          value: 4,
          label: "实体卡",
        },
        {
          value: 0,
          label: "余额",
        },
      ],
    };
  },
  created() {
    this.getTotalAmount();
  },
  methods: {
    //获取车辆列表
    setTableData(data = []) {
      this.tableData = data.map((v) => ({
        ...v,
        chargeTypeLabel: v.chargeType === 0 ? "余额" : "实体卡",
        amountYuan: (v.amount / 100).toFixed(2),
      }));
    },
    async getTotalAmount() {
      const res = await this.$Axios._post({
        url: this.$Config.apiUrl.getRechargeTotalAmount,
        params: { ...this.pageParam.params },
      });
      this.totalAmount = (res.data / 100).toFixed(2);
    },
    handlerRest() {
      this.pageParam.params = initParams();
      this.pageParam.freshCtrl++;
      this.getTotalAmount();
    },
    handlerSearch() {
      this.pageParam.freshCtrl++;
      this.getTotalAmount();
    },
  },
};
</script>
